import { useState, useLayoutEffect, useMemo } from "react";
import { changeEmailRequest } from "../../../services/changeEmail";
import { createRequestState } from "../../../utils";
import { EmailVerificationType, RequestState } from "../../../types";
import { changeEmailHookReturnType, changeEmailType } from "../types";

export default function useChangeEmail({
  token,
}: changeEmailType): changeEmailHookReturnType {
  const [emailPromise, setEmailPromise] = useState<
    Promise<EmailVerificationType>
  >();
  const [emailRequest, setEmailRequest] = useState(
    createRequestState<EmailVerificationType>()
  );

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        setEmailRequest({ isPending: true, data: null, error: null });
        const promise = changeEmailRequest({ token });

        setEmailPromise(promise);

        const data = await promise;
        setEmailRequest({ isPending: false, data, error: null });
      } catch (error) {
        setEmailRequest({ isPending: false, data: null, error });
      }
    };

    fetchData();
  }, [token]);

  return useMemo(() => ({ emailPromise, emailRequest }), [
    emailPromise,
    emailRequest,
  ]);
}
