import React, { ReactElement } from "react";

import Preloader from "../SVG/Preloader";
import useChangeEmail from "./hooks/useChangeEmail";
import { changeEmailType } from "./types";
import ErrorComponent from "../VerifyEmail/ErrorComponent";
import SuccessComponent from "../VerifyEmail/SuccessComponent";
import { PreloaderContainer } from "../Contract/Layout/styled";

const ChangeEmail = ({ token }: changeEmailType): ReactElement => {
  const {
    emailRequest: { isPending, data, error },
  } = useChangeEmail({ token });

  if (isPending) {
    return (
      <PreloaderContainer>
        <Preloader />
      </PreloaderContainer>
    );
  }

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <SuccessComponent
      newEmail={data?.customer.username as string}
      changeEmail={true}
    />
  );
};

export default ChangeEmail;
