import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";

import SEO from "../components/Seo";
import ChangeEmail from "../components/ChangeEmail";

interface EmailChangedProps extends RouteComponentProps {
  token: string;
}

const ChangeEmailPage = ({ token }: EmailChangedProps): ReactElement => (
  <div>
    <SEO title="Change Email" />
    <ChangeEmail token={token} />
  </div>
);

export default ChangeEmailPage;
